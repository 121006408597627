import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from 'hedron';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import AuthenticatorSubject, { mapPoolIdToSubject } from 'utils/Authenticator/AuthenticatorSubject';
import GS from 'styles/global';
import HistoryPropTypes from 'types/History';
import { getAssetURL } from 'utils/assetsHelper';

import SideNavbar from './SideNavbar';
import LS from './styles/PageFrameStyles';
import UpperNavbarItem from './UpperNavbarItem';

const mapStateToProps = (state) => {
  if (!state.user.pool) {
    return { subject: AuthenticatorSubject.NONE };
  }

  return { subject: mapPoolIdToSubject(state.user.pool.userPoolId) };
};

class PageFrame extends PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    navbarItems: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    subject: PropTypes.oneOf(AuthenticatorSubject.values()).isRequired,
    history: HistoryPropTypes.isRequired,
    fill: PropTypes.bool,
  };

  static defaultProps = {
    navbarItems: [],
    fill: false,
  };

  state = {
    hiddenUpperNavbar: true,
  };

  redirectWithRemember = target => () => {
    localStorage.setItem('pathname', this.props.history.location.pathname);

    this.props.history.push(target);
  };

  toggleUpperNavbar = () => this.setState(state => ({
    hiddenUpperNavbar: !state.hiddenUpperNavbar,
  }));

  shouldDisplayOrganizations = () => {
    const ORGANIZATION_FEATURE_IS_ENABLED = process.env.REACT_APP_FEATURE_FLAG_ORGANIZATIONS_ENABLED === 'true';
    return this.props.subject === AuthenticatorSubject.CLIENT && ORGANIZATION_FEATURE_IS_ENABLED;
  };

  // TODO this is a mess
  render() {
    if (this.props.subject === AuthenticatorSubject.NONE) {
      return (
        <Grid.Bounds direction="horizontal">
          <Grid.Bounds
            style={{ maxWidth: '100%' }}
            fill
            direction="vertical"
          >
            <Grid.Box fluid>
              <LS.UpperNavbarContainerNoUser>
                <Grid.Bounds
                  direction="horizontal"
                  sm={{ direction: 'vertical' }}
                >
                  <Grid.Box fluid>
                    <LS.Logo
                      src={getAssetURL('logo-white.svg')}
                      height="45"
                      alt="vizcareer company logo"
                    />
                  </Grid.Box>
                  <Grid.Box fill fluid>
                    <LS.ButtonHolder>
                      <GS.PrimaryButton
                        type="button"
                        onClick={this.redirectWithRemember('/auth')}
                      >
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']} />
                        &nbsp;Sign in
                      </GS.PrimaryButton>
                      <GS.PrimaryTransButton
                        type="button"
                        onClick={this.redirectWithRemember('/auth/register')}
                      >
                        <FontAwesomeIcon icon={['fas', 'user-plus']} />
                        &nbsp;Register
                      </GS.PrimaryTransButton>
                    </LS.ButtonHolder>
                  </Grid.Box>
                </Grid.Bounds>
              </LS.UpperNavbarContainerNoUser>
            </Grid.Box>
            <Grid.Box fluid>
              <LS.ContentNoUser fluid fill={this.props.fill}>
                {this.props.children}
              </LS.ContentNoUser>
            </Grid.Box>
          </Grid.Bounds>
        </Grid.Bounds>
      );
    }

    const isUpperNavbarEmpty = (this.props.navbarItems.length === 0);
    return (
      <Grid.Bounds
        sm={{ direction: 'vertical' }}
        md={{ direction: 'vertical' }}
        direction="horizontal"
      >
        <Grid.Box fluid>
          <SideNavbar
            onMenuToggle={this.toggleUpperNavbar}
          />
        </Grid.Box>
        <Grid.Bounds
          style={{ maxWidth: '100%' }}
          fill
          direction="vertical"
        >
          <Grid.Box fluid>
            <LS.UpperNavbarContainer
              empty={isUpperNavbarEmpty}
              sm={{
                direction: 'vertical',
                hidden: this.state.hiddenUpperNavbar,
              }}
            >
              {this.props.navbarItems}
              <Grid.Box
                fluid
                xl={{ hidden: true }}
                lg={{ hidden: true }}
                md={{ hidden: true }}
              >
                <Link to="/dashboard">
                  <UpperNavbarItem
                    icon={['fas', 'tachometer-alt']}
                    title="Dashboard"
                  />
                </Link>
              </Grid.Box>
              <Grid.Box
                fluid
                xl={{ hidden: true }}
                lg={{ hidden: true }}
                md={{ hidden: true }}
              >
                <Link to="/jobs">
                  <UpperNavbarItem
                    icon={['fas', 'id-card']}
                    title="Vacancies"
                  />
                </Link>
              </Grid.Box>
              <Grid.Box
                fluid
                xl={{ hidden: true }}
                lg={{ hidden: true }}
                md={{ hidden: true }}
              >
                <Link to="/profile/edit">
                  <UpperNavbarItem
                    icon={['fas', 'cog']}
                    title="Profile Edit"
                  />
                </Link>
              </Grid.Box>
              {this.shouldDisplayOrganizations() && (
                <Grid.Box
                  fluid
                  xl={{ hidden: true }}
                  lg={{ hidden: true }}
                  md={{ hidden: true }}
                >
                  <Link to="/organization">
                    <UpperNavbarItem
                      icon={['fas', 'users']}
                      title="Organisation Management"
                    />
                  </Link>
                </Grid.Box>
              )}
              {this.props.subject === AuthenticatorSubject.CLIENT && (
                <Grid.Box
                  fluid
                  xl={{ hidden: true }}
                  lg={{ hidden: true }}
                  md={{ hidden: true }}
                >
                  <Link to="/activity-feed">
                    <UpperNavbarItem
                      icon={['fas', 'chart-line']}
                      title="Reports"
                    />
                  </Link>
                </Grid.Box>
              )}
              {this.props && (
                <Grid.Box
                  fluid
                  xl={{ hidden: true }}
                  lg={{ hidden: true }}
                  md={{ hidden: true }}
                >
                  <Link to="/organization">
                    <UpperNavbarItem
                      icon={['fas', 'users']}
                      title="Organisation Management"
                    />
                  </Link>
                </Grid.Box>
              )}
              <Grid.Box
                fluid
                xl={{ hidden: true }}
                lg={{ hidden: true }}
                md={{ hidden: true }}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/contact"
                >
                  <UpperNavbarItem
                    icon={['fas', 'question-circle']}
                    title="Contact Us"
                  />
                </a>
              </Grid.Box>
              <Grid.Box
                fluid
                xl={{ hidden: true }}
                lg={{ hidden: true }}
                md={{ hidden: true }}
              >
                <Link to="/auth/logout">
                  <UpperNavbarItem
                    icon={['fas', 'door-open']}
                    title="Log Out"
                  />
                </Link>
              </Grid.Box>
            </LS.UpperNavbarContainer>
          </Grid.Box>
          <LS.Content emptyNavbar={isUpperNavbarEmpty} fluid fill={this.props.fill}>
            {this.props.children}
          </LS.Content>
        </Grid.Bounds>
      </Grid.Bounds>
    );
  }

}

export default connect(mapStateToProps)(PageFrame)


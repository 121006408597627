// @flow
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Grid from 'hedron';
import { useSelector } from 'react-redux';

import { AuthenticatorSubject, subjectSelector } from 'utils/Authenticator';
import Tooltip from 'core/Tooltip';
import { getAssetURL } from 'utils/assetsHelper';

import LS from './styles/SideNavbarStyles';

type Props = {
  onMenuToggle: () => void,
}

const shouldDisplayOrganizations = (subject) => {
  const ORGANIZATION_FEATURE_IS_ENABLED = process.env.REACT_APP_FEATURE_FLAG_ORGANIZATIONS_ENABLED === 'true';
  return subject === AuthenticatorSubject.CLIENT && ORGANIZATION_FEATURE_IS_ENABLED;
};

export default function SideNavbar({ onMenuToggle }: Props) {
  const subject = useSelector(subjectSelector);

  return (
    <LS.Container>
      <Grid.Bounds
        md={{ direction: 'horizontal' }}
        sm={{ direction: 'horizontal' }}
        direction="vertical"
      >
        <Grid.Box fluid>
          <Link to="/">
            <LS.Item>
              <LS.VizLogo
                src={getAssetURL('wordmark-white.svg')}
                alt="vizcareer v logo"
              />
            </LS.Item>
          </Link>
        </Grid.Box>
        <Grid.Box
          fluid
          fill
          xl={{ hidden: true }}
          lg={{ hidden: true }}
        />
        <Grid.Box
          fluid
          sm={{ hidden: true }}
        >
          <Link to="/dashboard">
            <Tooltip
              text="Dashboard"
              position="right"
            >
              <LS.Item>
                <FontAwesomeIcon icon={['fas', 'tachometer-alt']} />
              </LS.Item>
            </Tooltip>
          </Link>
        </Grid.Box>
        <Grid.Box
          fluid
          sm={{ hidden: true }}
        >
          <Link to="/jobs">
            <Tooltip
              text="Vacancies"
              position="right"
            >
              <LS.Item fluid>
                <FontAwesomeIcon icon={['fas', 'id-card']} />
              </LS.Item>
            </Tooltip>
          </Link>
        </Grid.Box>
        <Grid.Box
          fluid
          sm={{ hidden: true }}
        >
          <Link to="/profile/edit">
            <Tooltip
              text="Profile Edit"
              position="right"
            >
              <LS.Item fluid>
                <FontAwesomeIcon icon={['fas', 'cog']} />
              </LS.Item>
            </Tooltip>
          </Link>
        </Grid.Box>
        {shouldDisplayOrganizations(subject) && (
          <Grid.Box
            fluid
            sm={{ hidden: true }}
          >
            <Link to="/organization">
              <Tooltip
                text="Organisation Management"
                position="right"
              >
                <LS.Item fluid>
                  <FontAwesomeIcon icon={['fas', 'users']} />
                </LS.Item>
              </Tooltip>
            </Link>
          </Grid.Box>
        )}
        {subject === AuthenticatorSubject.CLIENT && (
          <Grid.Box
            fluid
            sm={{ hidden: true }}
          >
            <Link to="/live-interview">
              <Tooltip
                text="Live Interview"
                position="right"
              >
                <LS.Item fluid>
                  <svg width="26px" height="24px" viewBox="0 0 26 24">
                    <title>live-interview-icon</title>
                    <desc>Created with Sketch.</desc>
                    <defs />
                    <g
                      id="live-interview-icon"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g fill="currentColor" fillRule="nonzero" id="Shape">
                        <path
                          d="M14.7085142,11.7519698 C15.2866279,11.7485272 15.8550583,11.8936741 16.3541736,12.1721836 C16.8524302,12.4474023 17.2663614,12.8425612 17.5546546,13.3182212 C17.8479157,13.7943191 18.0016768,14.3369393 17.999833,14.8892477 L17.999833,15.8729299 C18.0052597,16.1738295 17.8782446,16.4631086 17.6496927,16.6703811 C17.4306643,16.8853496 17.128453,17.0045525 16.814358,16.9998669 L8.17589665,16.9998669 C7.86180157,17.0045525 7.55959033,16.8853496 7.34056195,16.6703811 C7.11517086,16.4617883 6.99177984,16.1724974 7.00042566,15.8729299 L7.00042566,14.8892477 C6.99681953,14.3373522 7.14886168,13.7947009 7.44060203,13.3182212 C7.7288952,12.8425612 8.14282646,12.4474023 8.64108305,12.1721836 C9.14019836,11.8936741 9.70862868,11.7485272 10.2867424,11.7519698 L10.7069108,11.7519698 C11.8441148,12.2483096 13.1511418,12.2483096 14.2883458,11.7519698 L14.7085142,11.7519698 Z M12.4976283,10.9974951 C11.3745128,11.009388 10.3321549,10.4415502 9.771536,9.51242137 C9.21803279,8.57414166 9.21803279,7.42802697 9.771536,6.48974726 C10.0433676,6.03513339 10.4369765,5.65772714 10.911993,5.3962364 C11.8966518,4.8679212 13.0986049,4.8679212 14.0832637,5.3962364 C15.0572953,5.93192412 15.6550786,6.92558804 15.648891,7.99869674 C15.6583686,8.79676683 15.3304599,9.56479117 14.7392926,10.1291482 C14.1481253,10.6935052 13.3436154,11.0065429 12.5076323,10.9974951 L12.4976283,10.9974951 Z"
                        />
                        <path
                          d="M23.0780901,21 L2.92189366,21 C1.30817635,21 1.97623374e-16,19.654291 0,17.9942748 L0,3.00572519 C-1.97623374e-16,1.34570901 1.30817635,3.04940026e-16 2.92189366,0 L23.0780901,0 C24.6918074,1.01646675e-16 25.9999837,1.34570901 25.9999837,3.00572519 L25.9999837,17.9842557 C26.0025725,18.7831581 25.6958742,19.5502525 25.147632,20.1161041 C24.5993898,20.6819558 23.8547149,21.0000044 23.0780901,21 Z M2.92189366,2.00381679 C2.38398789,2.00381679 1.94792911,2.45238646 1.94792911,3.00572519 L1.94792911,17.9842557 C1.94792911,18.5375945 2.38398789,18.9861641 2.92189366,18.9861641 L23.0780901,18.9861641 C23.6159959,18.9861641 24.0520546,18.5375945 24.0520546,17.9842557 L24.0520546,3.00572519 C24.0520546,2.45238646 23.6159959,2.00381679 23.0780901,2.00381679 L2.92189366,2.00381679 Z"
                        />
                        <polygon points="7 19 18 19 12.4974989 24" />
                      </g>
                    </g>
                  </svg>
                </LS.Item>
              </Tooltip>
            </Link>
          </Grid.Box>
        )}
        {subject === AuthenticatorSubject.CLIENT && (
          <Grid.Box
            fluid
            sm={{ hidden: true }}
          >
            <Link to="/activity-feed">
              <Tooltip
                text="Reports"
                position="right"
              >
                <LS.Item fluid>
                  <FontAwesomeIcon icon={['fas', 'chart-line']} />
                </LS.Item>
              </Tooltip>
            </Link>
          </Grid.Box>
        )}
        <Grid.Box
          fluid
          sm={{ hidden: true }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/contact"
          >
            <Tooltip
              text="Contact Us"
              position="right"
            >
              <LS.Item>
                <FontAwesomeIcon icon={['fas', 'question-circle']} />
              </LS.Item>
            </Tooltip>
          </a>
        </Grid.Box>

        <Grid.Box
          fluid
          sm={{ hidden: true }}
        >
          <Link to="/auth/logout">
            <Tooltip
              text="Log Out"
              position="right"
            >
              <LS.Item fluid>
                <FontAwesomeIcon icon={['fas', 'door-open']} />
              </LS.Item>
            </Tooltip>
          </Link>
        </Grid.Box>
        <Grid.Box
          fluid
          xl={{ hidden: true }}
          lg={{ hidden: true }}
          md={{ hidden: true }}
        >
          <LS.Item fluid onClick={onMenuToggle}>
            MENU <FontAwesomeIcon icon={['fas', 'bars']} />
          </LS.Item>
        </Grid.Box>
      </Grid.Bounds>
    </LS.Container>
  );
}
